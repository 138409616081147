import React, {useEffect, useState} from "react";
import {addEditAdmin,getPrivileges, get_response_or_errors, getAdmin, remove_session,addBuildingPrivileges,getBuildings} from "../../../functions/general";
import {useNavigate, useParams} from "react-router-dom";
import Select from "react-select";
import countryList from 'react-select-country-list'

function EditAdmins(props) {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState("");
    const [name, setName] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [isSuperAdmin, setIsSuperAdmin] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [emiratesId, setEmiratesId] = React.useState("");
    const [country, setCountry] = React.useState("");
    const [countryValue, setCountryValue] = React.useState("");
    const [city, setCity] = React.useState("");
    const [state, setState] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [status, setStatus] = React.useState(1);
    const [errors, setErrors] = React.useState("");
    const [buildingIdValues, setBuildingIdValues] = useState([]);
    const [selectedBuildingIds, setSelectedBuildingIds] = useState(null);
    const [buildingOptions, setBuildingOptions] = React.useState([]);
    const countries = React.useMemo(() => countryList().getData(), []);
    const [privileges, setPrivileges] = React.useState({
        owner: false,
        building: false,
        room: false,
        tenant: false,
        rent: false,
        transactions: false,
        employees: false,
        accounts: false
    });
    
    const countriesOptions = []
    countries?.length > 0 && countries.map((contry) => {
        countriesOptions.push({label: contry.label, value: contry.label})
    })
    useEffect(() => {
        
        getAdmin(params.id).then(res => {
            if (res && res.status == 200) {
                if(res.data.data){
                    setName(res.data.data.name);
                    setPhone(res.data.data.phone);
                    setEmail(res.data.data.email);
                    setEmiratesId(res.data.data.emiratesId);
                    setCountry(res.data.data.country);
                    setCountryValue({label: res.data.data.country, value: res.data.data.country});
                    setCity(res.data.data.city);
                    setState(res.data.data.state);
                    setAddress(res.data.data.address);
                    setStatus(res.data.data.status);
                    setIsSuperAdmin(res.data.data.isSuperAdmin);
                    // const privilegesData = res.data.privileges; 
                    const updatedPrivileges = { ...privileges };
                    Object.keys(res.data.privileges).forEach((privilege) => {
                       
                        updatedPrivileges[privilege] = (res.data.privileges[privilege] ==1)?true:false;
                    });
                   
                    // Update the privileges state with the updatedPrivileges object
                    setPrivileges(updatedPrivileges);
                    setSelectedBuildingIds(updatedPrivileges['building_ids']);
                } else {
                    navigate('/admins');
                }
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            }
        })
        getBuildings().then(res => {
            if (res && res.status == 200) {
                const buildingOptionsTemp = [];
                const selectedBuildingOptionsTemp = [];
                const parsedBuildingIds = JSON.parse(selectedBuildingIds);
                res.data.data?.length > 0 && res.data.data.forEach((buildingValue) => {
                    const label = buildingValue.name + " ( " + buildingValue.ownerName + " )";
                    const value = buildingValue.id;
                    if(parsedBuildingIds && parsedBuildingIds.includes(value)){ // Check if building ID exists in parsedBuildingIds
                        selectedBuildingOptionsTemp.push({ label, value });
                    } 
            
                    buildingOptionsTemp.push({ label, value });
                });
                setBuildingOptions(buildingOptionsTemp);
                setBuildingIdValues(selectedBuildingOptionsTemp);
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            } else {
            }
        })
    }, [selectedBuildingIds])
    const onSubmit = (e) => {
        e.preventDefault()
        setResponse("");
        if (loading)
            return false;
        if (validate()) {
            setLoading(true);
            const admin = {
                id: params.id,
                name: name,
                phone: phone,
                email: email,
                password: password,
                emiratesId: emiratesId,
                country: country,
                city: city,
                state: state,
                address: address,
                status: status,
                privileges: privileges
            }
            addEditAdmin(admin).then(res => {
                if (res && res.status == 203) {
                    remove_session();
                    props.getLoggedInUser();
                    navigate('/login');
                } else {
                    setResponse(get_response_or_errors(res))
                    setErrors(get_response_or_errors(res))
                    setLoading(false)
                }
            })
        }
    }
    const handleBuildingChange = (selectedOptions) => {
        
        setBuildingIdValues(selectedOptions);
    };
    const MultiValueLabel = ({ children, innerProps }) => (
        <div {...innerProps} className="react-select__multi-value__label">
            {children}
        </div>
    );
    const handleAddBuildingClick = () => {
        // Perform validation
        if (buildingIdValues.length === 0) {
            setErrors({ buildingId: 'Please select at least one building' });
            return;
        }

        // Clear any previous errors
        setErrors({});

        // Make API call
        const selectedBuildingIds = buildingIdValues.map((building) => building.value);
        const adminId =params.id;
        addBuildingPrivileges({selectedBuildingIds,adminId,privileges}).then(res => {
            if (res && res.status == 200) {
                navigate("/admins");
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            } else {
                setResponse(get_response_or_errors(res))
                setErrors(get_response_or_errors(res))
                setLoading(false)
            }
        })
    };

    const validate = () => {
        let errors = {
            name: '',
            phone: '',
            email: '',
            emiratesId: '',
            country: '',
            city: '',
            state: '',
            address: '',
        };
        let isValid = true;
        if (!name) {
            isValid = false;
            errors.name = "Please enter name.";
        }
        if (!phone) {
            isValid = false;
            errors.phone = "Please enter phone.";
        }
        if (!email) {
            isValid = false;
            errors.email = "Please enter email.";
        }
        if (!emiratesId) {
            isValid = false;
            errors.emiratesId = "Please enter emirates id.";
        }
        if (!country) {
            isValid = false;
            errors.country = "Please enter nationality.";
        }
        if (!city) {
            isValid = false;
            errors.city = "Please enter city.";
        }
        if (!state) {
            isValid = false;
            errors.state = "Please enter state.";
        }
        if (!address) {
            isValid = false;
            errors.address = "Please enter address.";
        }
        setErrors(errors);
        return isValid;
    }
    const handleCountryChange = (selectedOption) => {
        setCountry(selectedOption.value)
        setCountryValue(selectedOption)
    };
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setPrivileges({ ...privileges, [name]: checked });
    };

    return (
        <>
        <div className="col-lg-12">
          <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
              <div>
                  <h4 className="mb-3">Edit Admins</h4>
              </div>
          </div>
        </div>
        <div className="col-md-8">
          <div className="card">
              <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                  <div className="header-title">
                      <h4 className="card-title text-white">Edit Admin</h4>
                  </div>
              </div>
              <div className="card-body">
                  <form noValidate onSubmit={onSubmit}>
                      {response?.responseMessage &&
                      <div
                          className={`alert alert-${response?.responseFlag} fade show`}
                          role="alert">
                          {response?.responseMessage}
                      </div>
                      }
                      <div className="row">
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>Name *</label>
                                  <input type="text" className="form-control" placeholder="Enter Name"
                                         value={name} onChange={(e) => setName(e.target.value)}/>
                                      <div className="text-warning font-size-12">{errors.name}</div>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>Phone Number *</label>
                                  <input type="text" className="form-control" placeholder="Enter Phone Number"
                                         value={phone} onChange={(e) => setPhone(e.target.value)}/>
                                      <div className="text-warning font-size-12">{errors.phone}</div>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>Email *</label>
                                  <input type="text" className="form-control" placeholder="Enter Email"
                                         value={email} onChange={(e) => setEmail(e.target.value)}/>
                                  <div className="text-warning font-size-12">{errors.email}</div>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>Password</label>
                                  <input type="password" className="form-control" placeholder="Enter Password"
                                         value={password} onChange={(e) => setPassword(e.target.value)}/>
                                  <div className="text-warning font-size-12">{errors.password}</div>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>Emirates ID/Passport *</label>
                                  <input type="text" className="form-control" placeholder="Enter Emirates Id"
                                         value={emiratesId} onChange={(e) => setEmiratesId(e.target.value)}/>
                                  <div className="text-warning font-size-12">{errors.emiratesId}</div>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>Select Country *</label>
                                  <Select
                                      value={countryValue}
                                      onChange={handleCountryChange}
                                      options={countriesOptions}
                                  />
                                  <div className="text-warning font-size-12">{errors.country}</div>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>City *</label>
                                  <input type="text" className="form-control" placeholder="Enter City"
                                         value={city} onChange={(e) => setCity(e.target.value)}/>
                                  <div className="text-warning font-size-12">{errors.city}</div>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>State *</label>
                                  <input type="text" className="form-control" placeholder="Enter State"
                                         value={state} onChange={(e) => setState(e.target.value)}/>
                                  <div className="text-warning font-size-12">{errors.state}</div>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="form-group">
                                  <label>Address *</label>
                                  <textarea className="form-control" rows="4" value={address}
                                            onChange={(e) => setAddress(e.target.value)}></textarea>
                                  <div className="text-warning font-size-12">{errors.address}</div>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="form-group">
                                  <label>Status *</label>
                                  <select className="form-control"
                                         value={status} onChange={(e) => setStatus(e.target.value)}>
                                      <option value="1">Active</option>
                                      <option value="2">Inactive</option>
                                  </select>
                                  <div className="text-warning font-size-12">{errors.state}</div>
                              </div>
                          </div>
                      </div>
                      <button type="submit" className="btn btn-primary mr-2">Update</button>
                  </form>
              </div>
          </div>
        </div>
        <div className="col-md-4">
            <div className="col-md-12">
                <div className="card">
                    <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                        <div className="header-title">
                            <h4 className="card-title text-white">Privileges</h4>
                        </div>
                    </div>
                    <div className="card-body row">
                        <div className="form-check col-md-4 col-lg-3 col-xl-3 text-center">
                            <input className="form-control" type="checkbox" label="Owner" name="owner" checked={privileges.owner} onChange={handleCheckboxChange} />
                            <label className="" htmlFor="owner">Owner</label>
                        </div>
                        <div className="form-check col-md-4 col-lg-3 col-xl-3 text-center">
                            <input type="checkbox" className="form-control" label="Building" name="building" checked={privileges.building} onChange={handleCheckboxChange} />
                            <label className="" htmlFor="owner">Building</label>
                        </div>
                        <div className="form-check col-md-4 col-lg-3 col-xl-3 text-center">
                            <input type="checkbox" className="form-control" label="Room" name="room" checked={privileges.room} onChange={handleCheckboxChange} />
                            <label className="" htmlFor="owner">Room</label>
                        </div>
                        <div className="form-check col-md-4 col-lg-3 col-xl-3 text-center">
                            <input type="checkbox" className="form-control" label="Tenant" name="tenant" checked={privileges.tenant} onChange={handleCheckboxChange} />
                            <label className="" htmlFor="owner">Tenant</label>
                        </div>
                        <div className="form-check col-md-4 col-lg-3 col-xl-3 text-center">
                            <input type="checkbox" className="form-control" label="Rent" name="rent" checked={privileges.rent} onChange={handleCheckboxChange} />
                            <label className="" htmlFor="owner">Rent</label>
                        </div>
                        <div className="form-check col-md-4 col-lg-3 col-xl-3 text-center">
                            <input type="checkbox" className="form-control" label="Transactions" name="transactions" checked={privileges.transactions} onChange={handleCheckboxChange} />
                            <label className="" htmlFor="owner">Transactions</label>
                        </div>
                        <div className="form-check col-md-4 col-lg-3 col-xl-3 text-center">
                            <input type="checkbox" className="form-control" label="Employees" name="employees" checked={privileges.employees} onChange={handleCheckboxChange} />
                            <label className="" htmlFor="owner">Employees</label>
                        </div>
                        <div className="form-check col-md-4 col-lg-3 col-xl-3 text-center">
                            <input type="checkbox" className="form-control" label="Accounts"  name="accounts" checked={privileges.accounts} onChange={handleCheckboxChange} />
                            <label className="" htmlFor="owner">Accounts</label>
                        </div>
                        
                    </div>
                </div>
            </div>
            {privileges.building && (
                <div className="col-12" >
                <div className="card">
                    <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                        <div className="header-title">
                            <h4 className="card-title text-white">Buildings</h4>
                        </div>
                    </div>
                    <div className="card-body row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Building *</label>
                                <Select
                                    value={buildingIdValues}
                                    onChange={handleBuildingChange}
                                    options={buildingOptions}
                                    isMulti
                                    components={{ MultiValueLabel }}
                                />
                                <div className="text-warning font-size-12">{errors.buildingId}</div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-4">
                            <button className="btn btn-primary mr-2" onClick={handleAddBuildingClick}>Add</button>
                        </div>
                        
                    </div>
                </div>
            </div>
            )}
           
           
        </div>
       
        </>
    );
}

export default EditAdmins;
