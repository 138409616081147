
import React, {useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import logoImage from '../../../images/logoPrint.png'
import {
    date_time_to_date_format,
    getBuilding, getRent,
    getOwner,
    deleteCustomerDocuments,addCustomerDocuments,get_response_or_errors, remove_session
} from "../../../functions/general";
import ListTransactions from "../transactions/ListTransactions";
import PrintTransation from "../transactions/PrintTransation"
import ModalImage from "react-modal-image";
import { Col, Row } from "react-bootstrap";

function PrintRent(props) {
    const currentUser = props.currentUser;
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState("");
    const params = useParams();
  
    const [rent, setRent] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [owner, setOwner] = React.useState([]);
    const [ownerId, setOwnerId] = React.useState("");
   
    useEffect(() => {
        getRent(params.id).then(res => {
            if (res && res.status == 200) {
                if(res.data.data){
                    setRent(res.data.data);
                  
                } else {
                    navigate('/admins');
                }
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            }
        })
    }, [])
    const monthDiff = (dateFrom, dateTo) => {
        var months = dateTo.getMonth() - dateFrom.getMonth()
            + (12 * (dateTo.getFullYear() - dateFrom.getFullYear()));

        if(dateTo.getDate() < dateFrom.getDate()){
            months--;
        }
        return months;
    }
    const yearDiff = (dateFrom, dateTo) => {
        var years = dateTo.getFullYear() - dateFrom.getFullYear();

        if(dateTo.getDate() < dateFrom.getDate()){
            years--;
        }
        return years;
    }
    const getRentTillNow = (currentRent) => {
        let rentAmount = 0;
        if(currentRent.startDate){
            if(currentRent.rentType === '1'){
                let months = monthDiff(new Date(currentRent.startDate), new Date()) + 1;
                rentAmount = currentRent.rent * months
            }else{
                let years = yearDiff(new Date(currentRent.startDate), new Date()) + 1;
                rentAmount = currentRent.rent * years
            }
        }
        return rentAmount + parseInt(currentRent.overDaysRent);
    }
    const handlePrint = () => {
        window.print();
    };

    useEffect(() => {
        // Update the current page number when the component mounts
        const updatePageNumber = () => {
            setCurrentPage(window.location.href);
        };

    window.addEventListener("beforeprint", updatePageNumber);
    window.addEventListener("afterprint", updatePageNumber);

    return () => {
        window.removeEventListener("beforeprint", updatePageNumber);
        window.removeEventListener("afterprint", updatePageNumber);
    };
}, []);

  return (
      <div className="w-100 pt-0 mt-0 print-layout">
        <div className="text-right mb-3">
                    <button className="btn btn-primary  print-button" onClick={handlePrint}>
                    <i className="ri-printer-line mr-0"></i>
                    </button>
        </div> 
        <Row className="justify-content-center align-items-center header-text text-center mt-0">
            <Col md={4}>
                <h1 className="fw-bold">Royal Societe Property Management LLC</h1>
            </Col>
            <Col md={2} className="text-center">
                <Link to="/" className="print-logo-2 p-0 ">
                    <img src={logoImage} className="img-fluid p-0  " alt="logo"/>
                </Link>
            </Col>
            <Col md={4} className="text-left">
                <h1>رويال سوسيتيه</h1>
            </Col>
        </Row>
        <div className="col-12 mt-3">
            <div className="card print-card">
                <div className=" pb-0 mb-0 px-5">
                    <div className="text-center mb-3 red d-flex justify-content-start top">
                        <p className="bg-primary  red py-3 h4 px-5">
                            Rent Contract Detail
                        </p>
                    </div>
                    <div className="row tenant-print">
                            <div className="col-6">
                                <div className="form-group">
                                <label>Building: </label> {rent.buildingName}
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group ">
                                    <label>Room: </label>  
                                    <span className="ms-5 ps-5">{rent.roomName}</span>
                                
                                </div>
                            </div>

                           

                           {/* <div className="col-6">
                                <div className="form-group">
                                <label>Passport: </label> {customer.passport}
                                </div>
                            </div> */}
                            
                            <div className="col-6">
                                <div className="form-group">
                                <label>Tenant Name: </label> {rent.customerName} <br/>
                                <label>Tenant Email: </label> {rent.customerEmail} <br/>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="form-group">
                                <label>Rent: </label> {rent.rent} / {rent.rentType === '1' ? 'Month' : 'Year'}
                                      {rent.contractedImage &&
                                      <div  className="transactionTableImage">
                                          <ModalImage
                                              small={process.env.REACT_APP_APIPATH + "/api/images/" + rent.contractedImage}
                                              large={process.env.REACT_APP_APIPATH + "/api/images/" + rent.contractedImage}
                                          />
                                      </div>}
                                </div>
                            </div>
                            {rent.rentType === '2' && (
                                <div className="col-6">
                                    <div className="form-group">
                                        <br/>
                                        <label>No of Cheques: </label> {rent.cheques}
                                        
                                    </div>
                                </div>
                            )}
                            <div className="col-6">
                                <div className="form-group">
                                <label>Start Date: </label> {date_time_to_date_format(rent.startDate)}<br/>
                                {rent.endDate && (<>
                                    <label>End Date: </label> {date_time_to_date_format(rent.endDate)}
                                    <br/>
                                </>)}
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                <label>Dewa: </label> {rent.dewa === '1' ? 'Included' : 'Not Included'}<br/>
                                <label>Dewa Account : </label> {rent.dewaAccount? rent.dewaAccount : '--'}
                                      <br/>
                                      <label>Maintenance Charges: </label> {rent.maintenance === '1' ? 'Included' : 'Not Included'}
                                      <br/>
                                      <label>Security Deposit: </label> {rent.securityDeposit}
                                      <br/>
                                      <label>Over Days: </label> {rent.overDays}
                                      <br/>
                                      <label>Over Days Rent: </label> {rent.overDaysRent}
                                      {rent.rentType === '2' && (<>
                                          <br/>
                                          <label>Rent Start Date: </label> {date_time_to_date_format(rent.rentStartDate)}
                                          <br/>
                                          <label>Rent End Date: </label> {date_time_to_date_format(rent.rentEndDate)}
                                      </>)}
                                </div>
                            </div>
                        </div>
                        
                </div>
                
            </div>
        </div>
        {/* <ListTransactions currentUser={currentUser} rentId={params.id} transactionType="9" transactionFor="rent"/> */}
     
        
      </div>
  );
}

export default PrintRent;